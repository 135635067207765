.differences-table{
    border-collapse: collapse;    
}

.differences-table h4{
    margin-bottom: 0;
}

.dotted-border td, th {
    border: dotted 2px #64A19D;    
}

td, th {
    width: 50%;
    padding: 0.6rem 1rem;
}

td {
    color: rgba(0,0,0,0.7   )
}

.lima-blue{    
    font-weight: bold;
    color: #64A19D;
}