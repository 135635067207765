.signup-section{
  padding-top: 5rem;  
}

.contact-section{
  padding-bottom: 1rem;  
}

.contact-section a {
  color: #4abeb7 !important;
  margin: 0.2rem;
}

.contact-section .card hr {
  border-color: #4abeb7;
  border-width: 0.25rem;
  width: 3rem;
}

.icon-primary {
  color: #4abeb7 !important;
}

.contactForm{
  margin-bottom: 4rem;
  color: white;
}

