.fund-heading {
  background-color: #3199a7;
  color: white;
  text-align: right;
  padding: 0.4rem 1rem;
  font-size: 1.6rem;
}

.fund-modal h4 {
  font-size: 1.2rem;
  border-bottom: 4px solid #3199a7;
}

.key-facts p:nth-child(even) {
  background-color: #E5F5F5;
}

.key-facts p {
  font-size: 0.9rem;
  margin: 0;
}

.key-facts small {
  font-size: 0.6rem;
  line-height: 0.7rem;
}

.float-right {
  float: right;
}

@media (max-width: 769px) {
  .key-facts p {
    font-size: 0.6rem;
  }
  .key-facts small {
    font-size: 0.6rem;
  }
  .fund-modal {
    overflow-y: scroll;
  }
}
