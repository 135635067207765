.team-section{
  padding: 5rem 0;
}

.team-member {
  display: block;
  margin: auto;
}

.centered-team-member {
  max-width: 80%;
  margin: auto;
}

.centered-team-member h4 {
  margin-top: 1rem;
  margin-bottom: 0.4rem;
}

.img-team {
  display: circle;
  display: block;
  width: 100%;
  height: auto;
  filter: gray;
  filter: grayscale(100%);
}

.img-team:hover {
  transition: 0.5s ease;
  filter: grayscale(0%);
}

@media (min-width: 992px) {
  #team hr {
    border-color: #64a19d;
    border-width: 0.25rem;
    padding-bottom: 1rem;
  }
}

.social-icon{
  float: right;
}