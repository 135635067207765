.window-container {
  position: fixed;
  top: 0;
  background-color: #ccc;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
      to bottom,
      rgba(22, 22, 22, 0.1) 0%,
      rgba(22, 22, 22, 0.5) 75%,
      #161616 100%
    ),
    url(./../img/Parabola-no-line2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  font-size: 22px;
  z-index: 999999;
}

.exp {
  display: table;
  width: 100%;
  height: 100%;

  .checkbox {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle; 
    text-align: center;
  }
}

label {
  display: inline-block;
  color: #fff;
  cursor: pointer;
  position: relative;

  span {
    display: inline-block;
    position: relative;
    background-color: transparent;
    width: 25px;
    height: 25px;
    transform-origin: center;
    border: 2px solid #fff;
    border-radius: 50%;
    vertical-align: -6px;
    margin-right: 10px;
    transition: background-color 150ms 200ms,
    transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);     

    &:before {
      content: "";
      width: 0px;
      height: 2px;
      border-radius: 2px; 
      background: #fff;
      position: absolute;
      transform: rotate(45deg);
      top: 10px;
      left: 8px;
      transition: width 50ms ease 50ms;
      transform-origin: 0% 0%;
    }

    &:after {
      content: "";
      width: 0;
      height: 2px;
      border-radius: 2px; 
      background: #fff;
      position: absolute;
      transform: rotate(305deg);
      top: 13px;
      left: 9px;
      transition: width 50ms ease;
      transform-origin: 0% 0%;
    }
  }

  &:hover {
    color: #eee;
    span {
      &:before {
        width: 5px;
        transition: width 100ms ease;
      }

      &:after {
        width: 10px;
        transition: width 150ms ease 100ms;
      }
    }
  }
}



input[type="checkbox"] {
  display: none; 
  &:checked {
    + label {
      span {
        background-color: #fff;
        transform: scale(1.25);

        &:after {
          width: 10px;
          background: #64A19D;
          transition: width 150ms ease 100ms;
        }

        &:before {
          width: 5px;
          background: #64A19D;
          transition: width 150ms ease 100ms;
        }
      }

      &:hover {

        span {
          background-color: #fff;
          transform: scale(1.25);

          &:after {
            width: 10px;
            background: #64A19D;
            transition: width 150ms ease 100ms;
          }

          &:before {
            width: 5px;
            background: #64A19D;
            transition: width 150ms ease 100ms;
          }
        }
      }
    }
  }
}
