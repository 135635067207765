.navigation-bar {
  background-color: transparent;
}

.navigation-bar-scroll {
  transition: 0.5s ease;
  background-color: white !important;
  border-bottom: 1px solid #ccc;
}

.navigation-bar a {
  color: white !important;
  /* font-weight: bold; */
}

.navigation-bar-scroll a {
  color: black !important;
}

.navigation-bar a:hover {
  color: #ccc !important;
}

.header-logo-image {
  height: 2rem;
}

.hidden {
  display: none;
}

.nav-item {
  padding: 0 1rem;
}

@media only screen and (max-width: 767px) {
  .header-logo-image {
    display: none;
  }
  .navigation-bar {
    background-color: white;
  }
  .navigation-bar a {
    color: black !important;
  }
}
